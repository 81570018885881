<template>
	<div class="grid lg:grid-cols-3 gap-5">
		<div class="lg:col-span-2 h-full w-full overflow-y-auto">
			<n-data-table title="採購列表" :columns="fields" :data="purchaseOrders" actions="view,edit,search,refresh" @view="getPurchaseOrder" @edit="archivePurchaseOrder">
				<template v-slot:merchant="{ row }"> {{ row.merchant.name['tc'] }}</template>
				<template v-slot:location="{ row }"> {{ row.location.name['tc'] }}</template>
				<template v-slot:supplier="{ row }"> {{ row.supplier.name }}</template>
				<template v-slot:status="{ row }">
					<n-badge :color="adjustmentStatusName(row.status).color"> {{ adjustmentStatusName(row.status).label }}</n-badge>
				</template>
			</n-data-table>
		</div>
		<div class="lg:col-span-1 space-y-2 text-xl h-full overflow-auto">
			<p class="text-4xl pb-2">{{ purchaseOrder.id ? '修改' : '新增' }}採購</p>
			<div class="flex space-x-2">
				<label for="merchant">所屬商戶</label>
				<n-select v-model="purchaseOrder.merchant" :options="merchants" @input="purchaseOrder.merchantId = purchaseOrder.merchant.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇商戶"></n-select>
			</div>

			<div v-if="purchaseOrder.merchant.id" class="flex space-x-2">
				<label for="name">入庫位置</label>
				<n-select v-model="purchaseOrder.location" :options="locationByMerchantId(purchaseOrder.merchant.id)" @input="purchaseOrder.locationId = purchaseOrder.location" :reduce="option => option.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇入庫位置"></n-select>
			</div>
			<div v-if="purchaseOrder.merchant.id" class="flex space-x-2">
				<label for="name">供應商</label>
				<n-select v-model="purchaseOrder.supplier" :options="supplierByMerchantId(purchaseOrder.merchant.id)" @input="purchaseOrder.supplierId = purchaseOrder.supplier" :reduce="option => option.id" :get-option-label="option => option.name" class="w-full h-full text-base" placeholder="請選擇供應商"></n-select>
			</div>
			<div v-if="purchaseOrder.items.length > 0" class="space-y-2">
				<p class="text-xl">商品採購列表</p>
				<div v-for="(item, index) in purchaseOrder.items" :key="(item, index)">
					<div class="flex space-x-2">
						<label for="product">商品</label>
						<n-select v-model="item.product" :options="catalogByMerchantId(purchaseOrder.merchant.id, 'PRODUCT')" @input="item.productId = item.product.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇商品"></n-select>
					</div>
					<div class="flex space-x-2">
						<label for="variant">變化</label>
						<n-select v-model="item.variant" :options="catalogByMerchantId(purchaseOrder.merchant.id, 'VARIANT')" @input="item.variantId = item.variant.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇類別"></n-select>
					</div>
					<div class="flex space-x-2">
						<label for="number">數量</label>
						<input type="number" v-model.number="item.quantity" class="w-full h-full text-base border border-gray px-3" placeholder="請選擇數量" />
					</div>
					<div class="flex space-x-2">
						<label for="number">價錢</label>
						<input type="number" v-model.number="item.price" class="w-full h-full text-base border border-gray px-3" placeholder="請選擇價錢" />
					</div>
				</div>
			</div>

			<div v-if="purchaseOrder.merchant.id" class="space-y-2">
				<p class="text-xl">加入新商品</p>
				<div class="flex space-x-2">
					<label for="product">商品</label>
					<n-select
						v-model="purchaseItem.product"
						:options="catalogByMerchantId(purchaseOrder.merchant.id, 'PRODUCT')"
						@input="
							purchaseItem.productId = purchaseItem.product.id
							purchaseItem.variant = ''
						"
						:get-option-label="option => option.name['tc']"
						class="w-full h-full text-base"
						placeholder="請選擇商品"
					></n-select>
				</div>
				<div class="flex space-x-2">
					<label for="variant">變化</label>
					<n-select v-model="purchaseItem.variant" :options="catalogByMerchantId(purchaseOrder.merchant.id, 'VARIANT')" @input="purchaseItem.variantId = purchaseItem.variant.id" :get-option-label="option => option.name['tc']" class="w-full h-full text-base" placeholder="請選擇類別"></n-select>
				</div>

				<div class="flex space-x-2">
					<label for="number">數量</label>
					<input type="number" v-model.number="purchaseItem.quantity" class="w-full h-full text-base border border-gray px-3" placeholder="請選擇類別" />
				</div>

				<div class="flex space-x-2">
					<label for="number">價錢</label>
					<input type="number" v-model.number="purchaseItem.price" class="w-full h-full text-base border border-gray px-3" placeholder="請選擇價錢" />
				</div>

				<n-button @onClick="addPurchaseItem(purchaseItem)">加入新商品</n-button>

				<div class="flex space-x-2">
					<label for="status">採購狀態</label>
					<n-select v-model="purchaseOrder.status" :options="adjustmentStatusOptions" :reduce="status => status.value" class="w-full h-full text-base" placeholder="請選擇狀態"></n-select>
				</div>

				<p v-if="error" class="text-danger">{{ '錯誤訊息:' + error }}</p>

				<div class="flex space-x-2">
					<n-button v-if="purchaseOrder.id" @onClick="$router.go(0)" color="danger">返回</n-button>
					<n-button @onClick="purchaseOrder.id ? updatePurchaseOrder(purchaseOrder) : createPurchaseOrder(purchaseOrder)">{{ purchaseOrder.id ? '修改採購' : '加入採購' }}</n-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { purchaseOrder, currentPurchaseItem } from '@/model/purchaseOrder'

export default {
	name: 'Home',
	data() {
		return {
			purchaseItem: currentPurchaseItem(),
			purchaseOrder: purchaseOrder(),
			fields: [
				{
					label: '採購編號',
					prop: 'referenceId',
					sortable: true,
				},
				{
					label: '所屬商戶',
					prop: 'merchant',
					sortable: true,
				},
				{
					label: '入庫位置',
					prop: 'location',
					sortable: true,
				},
				{
					label: '供應商',
					prop: 'supplier',
					sortable: true,
				},
				{
					label: '商品小計',
					prop: 'productTotal',
					sortable: true,
				},
				{
					label: '總計',
					prop: 'grandTotal',
					sortable: true,
				},
				{
					label: '庫存狀態',
					prop: 'status',
					sortable: true,
				},
			],
			error: '',
		}
	},
	computed: {
		...mapState(['statusOptions', 'adjustmentStatusOptions']),
		...mapGetters([
			// merchant
			'merchants',
			'merchantName',
			'currentMerchant',
			// supplier
			'suppliers',
			'supplierByMerchantId',
			'supplierName',
			// location
			'locations',
			'locationByMerchantId',
			'locationName',
			// catalog
			'catalog',
			'catalogMerchant',
			'catalogByMerchantId',
			'catalogItem',
			// inventory
			'inventories',
			'currentInventory',
			// purchaseOrder
			'purchaseOrders',
			'currentPurchaseOrder',
			// state
			'statusName',
			'adjustmentStatusName',
		]),
	},
	methods: {
		async addPurchaseItem(purchaseItem) {
			if (!purchaseItem.product) return
			if (!purchaseItem.variant) return
			await this.purchaseOrder.items.push(purchaseItem)
			this.purchaseItem = await currentPurchaseItem()
		},

		async createPurchaseOrder(data) {
			try {
				data.merchantId = data.merchant.id
				data.locationId = data.location
				data.supplierId = data.supplier
				data.items.forEach(item => ((item.productId = item.product.id), (item.variantId = item.variant.id)))
				await this.$store.dispatch('createPurchaseOrder', { purchaseOrder: data })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async getPurchaseOrder(row) {
			try {
				const id = row.id
				this.error = ''
				await this.$store.dispatch('getPurchaseOrder', { id })
				this.purchaseOrder = this.currentPurchaseOrder
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async archivePurchaseOrder(row) {
			try {
				const id = row.id
				this.error = ''
				await this.$store.dispatch('archivePurchaseOrder', { id })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async updatePurchaseOrder(data) {
			try {
				data.merchantId = data.merchant.id
				data.locationId = data.location.id
				data.supplierId = data.supplier.id
				data.items.forEach(item => ((item.productId = item.product.id), (item.variantId = item.variant.id)))
				this.error = ''
				await this.$store.dispatch('updatePurchaseOrder', { id: data.id, purchaseOrder: data })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
	},
}
</script>
