export function purchaseOrder() {
    return {
        merchant: [],
        location: [],
        items: [],
        status: '',
    }
}

export function currentPurchaseItem() {
    return {
        product: '',
        variant: '',
        quantity: 0,
        price: 0,
    }
}
